import React, { ChangeEvent, useState } from "react";
import Comment from "../../../models/Comment";
import { FaThumbsUp } from "react-icons/fa";

type Props = {
    item: Comment;
    setTruePositive: (id: string) => void;
    setFalsePositive: (id: string) => void;
};

const CommentListItem = ({
    item,
    setTruePositive,
    setFalsePositive,
}: Props) => {
    const [isTruePositive, setIsTruePositive] = useState(false);
    // const [isCommentTextCopied, setIsCommentTextCopied] = useState(false);
    const [isCommentIdCopied, setIsCommentIdCopied] = useState(false);
    const [isProcessed, setIsProcessed] = useState(false);

    const copyTextToClipboard = async (text: string) => {
        if ("clipboard" in navigator) {
            return await navigator.clipboard.writeText(
                text.replace(/(<([^>]+)>)/gi, "").replace(/\r?\n|\r/g, " "),
            );
        } else {
            return document.execCommand("copy", true, text);
        }
    };

    // const handleCommentTextCopyClick = async (event: React.FormEvent) => {
    //     event.stopPropagation();
    //     try {
    //         await copyTextToClipboard(
    //             item.snippet.topLevelComment.snippet.textOriginal,
    //         );
    //         setIsCommentTextCopied(true);
    //         setIsProcessed(true);
    //         setTimeout(() => {
    //             setIsCommentTextCopied(false);
    //         }, 1500);
    //     } catch (error: any) {
    //         console.log(error);
    //     }
    // };

    // const handleMarkAsProcessedClick = (event: React.FormEvent) => {
    //     event.stopPropagation();
    //     setIsProcessed(true);
    // };

    const handleCommentIdCopyClick = async (event: React.FormEvent) => {
        event.stopPropagation();
        try {
            await copyTextToClipboard(item.snippet.topLevelComment.id);
            setIsCommentIdCopied(true);
            setTimeout(() => {
                setIsCommentIdCopied(false);
            }, 1500);
        } catch (error: any) {
            console.log(error);
        }
    };

    const handleIsTruePositiveToggle = (e: ChangeEvent<HTMLInputElement>) => {
        setIsTruePositive(e.target.checked);
        if (e.target.checked) setTruePositive(item.id);
        else setFalsePositive(item.id);
    };

    return (
        <li
            className={`list-group-item d-flex flex-column gap-2 ${
                isProcessed && "bg-secondary-subtle"
            }`}
        >
            <div className="d-flex align-items-center justify-content-between gap-3">
                <div>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                            item.snippet.topLevelComment.snippet
                                .authorChannelUrl
                        }
                        className="text-decoration-none"
                    >
                        <div className="d-flex gap-2 align-items-center">
                            <img
                                src={
                                    item.snippet.topLevelComment.snippet
                                        .authorProfileImageUrl
                                }
                                width="20"
                            />
                            <span style={{ fontSize: "0.8em" }}>
                                {
                                    item.snippet.topLevelComment.snippet
                                        .authorDisplayName
                                }
                            </span>
                        </div>
                    </a>
                </div>
                <div className="text-secondary form-text">
                    <div className="d-flex gap-1 align-items-center">
                        {/*{item.snippet.topLevelComment.id}*/}
                        <button
                            className="btn btn-sm btn-link text-decoration-none"
                            onClick={handleCommentIdCopyClick}
                        >
                            <span>
                                {isCommentIdCopied ? "Copied!" : "Copy Id"}
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            <div
                dangerouslySetInnerHTML={{
                    __html: item.snippet.topLevelComment.snippet.textPreprocessed,
                }}
            ></div>
            <div className="d-flex justify-content-between">
                <div className="d-flex gap-3 align-items-center">
                    <div className="d-flex gap-1">
                        <FaThumbsUp className="mt-1" />
                        {item.snippet.topLevelComment.snippet.likeCount}
                    </div>
                </div>
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        onChange={handleIsTruePositiveToggle}
                        checked={isTruePositive}
                    />
                    <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckDefault"
                    >
                        True positive
                    </label>
                </div>
                {/*<button*/}
                {/*    className="btn btn-sm btn-link text-decoration-none"*/}
                {/*    type="submit"*/}
                {/*    onClick={handleCommentTextCopyClick}*/}
                {/*>*/}
                {/*    <span>{isCommentTextCopied ? "Copied!" : "Copy Text"}</span>*/}
                {/*</button>*/}
                {/*<button*/}
                {/*    className="btn btn-sm btn-link text-decoration-none"*/}
                {/*    onClick={handleMarkAsProcessedClick}*/}
                {/*>*/}
                {/*    <span>*/}
                {/*        {isProcessed ? "Processed" : "Mark as processed"}*/}
                {/*    </span>*/}
                {/*</button>*/}
            </div>
        </li>
    );
};

export default CommentListItem;
