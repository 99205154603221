import Comment from "../models/Comment";

export const returnIfRequestComment = (
    comment: Comment,
    keywordSet: string[][],
): Comment | null => {
    let isVideoRequest: boolean = false;
    let finalText: string = "";

    for (const wordSet of keywordSet) {
        let match: boolean = false;
        let textOriginal = comment.snippet.topLevelComment.snippet.textOriginal;
        textOriginal = preprocessCommentText(textOriginal);
        let idx = 0;
        finalText = "";
        for (let i = 0; i < wordSet.length; i++) {
            const word: string = wordSet[i];
            textOriginal = textOriginal.substring(idx);

            if (textOriginal.indexOf(word) === -1) break;

            // ignore comments with too many words between the keywords in the set
            if (
                i > 0 &&
                (
                    textOriginal
                        .substring(0, textOriginal.indexOf(word))
                        .match(/ /g) || []
                ).length > 5
            )
                break;

            textOriginal =
                textOriginal.substring(0, textOriginal.indexOf(word)) +
                "<b>" +
                word +
                "</b>" +
                textOriginal.substring(
                    textOriginal.indexOf(word) + word.length,
                );

            idx = textOriginal.indexOf(word) + word.length + "</b>".length;
            finalText += textOriginal.substring(0, idx);

            if (i === wordSet.length - 1) {
                match = true;
            }

            if (i === wordSet.length - 1) {
                finalText += textOriginal.substring(idx);
            }
        }

        if (match) {
            isVideoRequest = true;
            comment.snippet.topLevelComment.snippet.textPreprocessed = finalText;
            break;
        }
    }

    if (isVideoRequest) return comment;
    return null;
};

export const preprocessCommentText = (commentText: string) => {
    commentText = commentText.toLowerCase();

    if (commentText.indexOf("u ") === 0) {
        // replace only the first match
        commentText = commentText.replace("u ", "you ");
    }
    commentText = commentText.replace(/ u /gi, " you ");
    // commentText = commentText.replace(/ u/gi, " you");

    if (commentText.indexOf("pls ") === 0) {
        // replace only the first match
        commentText = commentText.replace("pls ", "please ");
    }
    commentText = commentText.replace(/ pls /gi, " please ");
    commentText = commentText.replace(/ pls/gi, " please");

    if (commentText.indexOf("plz ") === 0) {
        // replace only the first match
        commentText = commentText.replace("plz ", "please ");
    }
    commentText = commentText.replace(/ plz /gi, " please ");
    commentText = commentText.replace(/ plz/gi, " please");

    if (commentText.indexOf("pleaaase ") === 0) {
        // replace only the first match
        commentText = commentText.replace("pleaaase ", "please ");
    }
    commentText = commentText.replace(/ pleaaase /gi, " please ");
    commentText = commentText.replace(/ pleaaase/gi, " please");

    if (commentText.indexOf("pleassseeeee ") === 0) {
        // replace only the first match
        commentText = commentText.replace("pleassseeeee ", "please ");
    }
    commentText = commentText.replace(/ pleassseeeee /gi, " please ");
    commentText = commentText.replace(/ pleassseeeee/gi, " please");

    if (commentText.indexOf("pleassssse ") === 0) {
        // replace only the first match
        commentText = commentText.replace("pleassssse ", "please ");
    }
    commentText = commentText.replace(/ pleassssse /gi, " please ");
    commentText = commentText.replace(/ pleassssse/gi, " please");

    if (commentText.indexOf("pleace ") === 0) {
        // replace only the first match
        commentText = commentText.replace("pleace ", "please ");
    }
    commentText = commentText.replace(/ pleace /gi, " please ");
    commentText = commentText.replace(/ pleace/gi, " please");

    if (commentText.indexOf("pleeaseee ") === 0) {
        // replace only the first match
        commentText = commentText.replace("pleeaseee ", "please ");
    }
    commentText = commentText.replace(/ pleeaseee /gi, " please ");
    commentText = commentText.replace(/ pleeaseee/gi, " please");

    if (commentText.indexOf("pleaase ") === 0) {
        // replace only the first match
        commentText = commentText.replace("pleaase ", "please ");
    }
    commentText = commentText.replace(/ pleaase /gi, " please ");
    commentText = commentText.replace(/ pleaase/gi, " please");

    if (commentText.indexOf("pleeeeease ") === 0) {
        // replace only the first match
        commentText = commentText.replace("pleeeeease ", "please ");
    }
    commentText = commentText.replace(/ pleeeeease /gi, " please ");
    commentText = commentText.replace(/ pleeeeease/gi, " please");

    if (commentText.indexOf("i'd ") === 0) {
        // replace only the first match
        commentText = commentText.replace("i'd ", "i would ");
    }
    commentText = commentText.replace(/ i'd /gi, " i would ");
    commentText = commentText.replace(/ i'd/gi, " i would");

    if (commentText.indexOf("i’d ") === 0) {
        // replace only the first match
        commentText = commentText.replace("i’d ", "i would ");
    }
    commentText = commentText.replace(/ i’d /gi, " i would ");
    commentText = commentText.replace(/ i’d/gi, " i would");

    return commentText;
};

export const copyTextToClipboard = async (text: string) => {
    if ("clipboard" in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand("copy", true, text);
    }
};

export const commentsToCsv = (comments: Comment[]): string => {
    let csv: string = "";
    for (let row = 0; row < comments.length; row++) {
        csv +=
            comments[row].id +
            "\t" +
            comments[row].snippet.topLevelComment.snippet.textOriginal
                .replace(/(<([^>]+)>)/gi, "")
                .replace(/\r?\n|\r/g, " ") +
            "\t" +
            "https://www.youtube.com/watch?v=" +
            comments[row].snippet.videoId +
            "\n";
    }
    return csv;
};
