import React, { useState } from "react";
import CommentsList from "../../components/CommentsList/CommentsList";
import Comment from "../../models/Comment";
import { getComments as apiGetComments } from "../../api/getComments";
import { getVideo as apiGetVideo } from "../../api/getVideo";
import { keywordsSet } from "../../utils/keysets";
import Spinner from "../../components/UI/Spinner/Spinner";
import {
    commentsToCsv,
    copyTextToClipboard,
    returnIfRequestComment,
} from "../../utils/helpers";
import Video from "../../models/Video";

const HomePage = () => {
    const [apiKey, setApiKey] = useState<string>("");
    const [videoId, setVideoId] = useState<string>();
    const [video, setVideo] = useState<Video>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [videoRequestCommentThreads, setVideoRequestCommentThreads] =
        useState<Comment[]>([]);
    const [otherCommentThreads, setOtherCommentThreads] = useState<Comment[]>(
        [],
    );
    const [truePositiveCommentIds, setTruePositiveCommentIds] = useState<
        string[]
    >([]);
    const [truePositivesCopied, setTruePositivesCopied] = useState(false);
    const [falsePositivesCopied, setFalsePositivesCopied] = useState(false);
    const [allCopied, setAllCopied] = useState(false);
    const [fetchedCommentsCount, setFetchedCommentsCount] = useState<number>(0);

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const urlStr = event.currentTarget.value;
        if (urlStr) {
            const url = new URL(event.currentTarget.value);
            const searchParams = url.searchParams;

            const vidId = searchParams.get("v")!;
            setVideoId(vidId);
        } else setVideoId(undefined);
    };

    const getVideo = async () => {
        if (videoId) {
            try {
                const response: any = await apiGetVideo(apiKey, videoId);
                setVideo(response.items[0]);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const getComments = async (nextPageToken?: string) => {
        const comments = [];
        if (videoId) {
            setIsLoading(true);
            let done = false;

            while (!done) {
                try {
                    const response: any = await apiGetComments(
                        apiKey,
                        videoId,
                        nextPageToken,
                    );
                    comments.push(...response.items);
                    setFetchedCommentsCount(comments.length);
                    nextPageToken = response.nextPageToken;
                    if (!nextPageToken) done = true;
                } catch (error) {
                    console.log(error);
                    done = true;
                }
            }
            setTruePositiveCommentIds([]);
            setIsLoading(false);
        }
        analyzeCommentsWithKeywordSet(comments, keywordsSet);
    };

    const analyzeCommentsWithKeywordSet = (
        commentThreads: Comment[],
        keywordSet: string[][],
    ) => {
        const requestComments: Comment[] = [];
        const otherComments: Comment[] = [];

        for (const item of commentThreads) {
            let comment = returnIfRequestComment(item, keywordSet);
            if (comment) {
                requestComments.push(comment);
            } else {
                otherComments.push(item);
            }
        }

        requestComments.sort(
            (i1, i2) =>
                i2.snippet.topLevelComment.snippet.likeCount -
                i1.snippet.topLevelComment.snippet.likeCount,
        );
        otherComments.sort(
            (i1, i2) =>
                i2.snippet.topLevelComment.snippet.likeCount -
                i1.snippet.topLevelComment.snippet.likeCount,
        );

        setVideoRequestCommentThreads(requestComments);
        setOtherCommentThreads(otherComments);
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        setVideo(undefined);
        await getVideo();
        await getComments();
    };

    const setTruePositive = (id: string) => {
        if (!truePositiveCommentIds.includes(id))
            setTruePositiveCommentIds((prev: string[]) => [...prev, id]);
    };

    const setFalsePositive = (id: string) => {
        if (truePositiveCommentIds.includes(id)) {
            setTruePositiveCommentIds((prev: string[]) =>
                prev.filter((obj) => obj !== id),
            );
        }
    };

    const handleCopyToClipboardClick = (type: "truePositive"|"falsePositive"|"all") => {
        let csv: string = "";
        let items: Comment[] = [];
        if (type === "truePositive")
            items = videoRequestCommentThreads.filter((item) =>
                truePositiveCommentIds.includes(item.id),
            );
        else if (type === "falsePositive")
            items = videoRequestCommentThreads.filter(
                (item) => !truePositiveCommentIds.includes(item.id),
            );
        else
            items = videoRequestCommentThreads.concat(otherCommentThreads)

        csv = commentsToCsv(items);
        copyTextToClipboard(csv);
        try {
            if (type === "truePositive") {
                setTruePositivesCopied(true);
                setTimeout(() => {
                    setTruePositivesCopied(false);
                }, 1500);
            } else if (type === "falsePositive") {
                setFalsePositivesCopied(true);
                setTimeout(() => {
                    setFalsePositivesCopied(false);
                }, 1500);
            } else {
                setAllCopied(true);
                setTimeout(() => {
                    setAllCopied(false);
                }, 1500);
            }
        } catch (error: any) {
            console.log(error);
        }
    };

    return (
        <>
            <div className="container mt-5">
                <div className="row">
                    <div className="col">
                        <div className="d-flex align-items-center gap-3 mb-2">
                            <h3>Ablebees YouTube Comments Analyser</h3>
                            <span>v{process.env.REACT_APP_VERSION}</span>
                        </div>
                        <form onSubmit={submitHandler}>
                            <input
                                type="text"
                                className="form-control mb-3"
                                placeholder="API key"
                                onChange={(event) =>
                                    setApiKey(event.currentTarget.value)
                                }
                                required={true}
                            />
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="YouTube Video URL"
                                    onChange={changeHandler}
                                    required={true}
                                />
                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                >
                                    Fetch
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                {isLoading && (
                    <div className="row mt-5">
                        <div className="col">
                            <p>
                                Fetched comments count: {fetchedCommentsCount}
                            </p>
                        </div>
                    </div>
                )}
                {!isLoading && video && (
                    <div className="row my-3">
                        <p className="mb-0 fw-bold">
                            {video.snippet.channelTitle}
                        </p>
                        <p className="fw-medium">{video.snippet.title}</p>
                    </div>
                )}
                {!isLoading &&
                    (videoRequestCommentThreads.length > 0 ||
                        otherCommentThreads.length > 0) && (
                        <div className="row mb-5">
                            <div className="col d-flex flex-column gap-3">
                                <label>
                                    Video Request Comments{" "}
                                    <span>
                                        ({videoRequestCommentThreads.length} /{" "}
                                        {videoRequestCommentThreads.length +
                                            otherCommentThreads.length}
                                        )
                                    </span>
                                </label>
                                <CommentsList
                                    items={videoRequestCommentThreads}
                                    setTruePositive={setTruePositive}
                                    setFalsePositive={setFalsePositive}
                                />
                                <div className="d-flex gap-2 justify-content-end">
                                    <button
                                        className="btn btn-success btn-sm"
                                        onClick={() =>
                                            handleCopyToClipboardClick(
                                                "truePositive"
                                            )
                                        }
                                        disabled={
                                            truePositiveCommentIds.length === 0
                                        }
                                    >
                                        <span>
                                            {truePositivesCopied
                                                ? "Copied!"
                                                : `Copy true positives (${truePositiveCommentIds.length})`}
                                        </span>
                                    </button>
                                    <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() =>
                                            handleCopyToClipboardClick(
                                                "falsePositive"
                                            )
                                        }
                                        disabled={
                                            videoRequestCommentThreads.length ===
                                            0
                                        }
                                    >
                                        <span>
                                            {falsePositivesCopied
                                                ? "Copied!"
                                                : `Copy false positives (${
                                                    videoRequestCommentThreads.length -
                                                    truePositiveCommentIds.length
                                                })`}
                                        </span>
                                    </button>
                                    <button
                                        className="btn btn-dark btn-sm"
                                        onClick={() =>
                                            handleCopyToClipboardClick(
                                                "all"
                                            )
                                        }
                                        disabled={
                                            videoRequestCommentThreads.length ===
                                            0
                                        }
                                    >
                                        <span>
                                            {allCopied
                                                ? "Copied!"
                                                : `Copy all (${
                                                    videoRequestCommentThreads.length +
                                                    otherCommentThreads.length
                                                })`}
                                        </span>
                                    </button>
                                </div>
                                {isLoading && <Spinner />}
                            </div>
                            {/*<div className="col-sm-6 mt-5 mt-sm-0 d-flex flex-column gap-3">*/}
                            {/*    <label>*/}
                            {/*        Other Comments{" "}*/}
                            {/*        <span>({otherCommentThreads.length})</span>*/}
                            {/*    </label>*/}
                            {/*    <CommentsList items={otherCommentThreads} />*/}
                            {/*    {isLoading && <Spinner />}*/}
                            {/*</div>*/}
                        </div>
                    )}
            </div>
        </>
    );
};

export default HomePage;
